// Images
import logoCT from "assets/team.jpg";

export default {
  brand: {
    name: "DiabeticGuardian",
    image: logoCT,
    route: "/",
  },
  socials: [
  ],
  menus: [
  ],
};
